<template>
  <section>
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm1"
          :model="selectForm1"
          class="select_form"
        >
          <el-form-item label="公司">
            <el-input v-model="selectForm1.Month" placeholder=""></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="new_css calc-height">
      <!--列表-->

      <el-table
        :data="users"
        highlight-current-row
        height="100%"
        @row-dblclick="handleEdit"
        @current-change="selectCurrentRow"
        class="new_table"
      >
        <el-table-column prop="CreateUserName" label="创建人">
        </el-table-column>
        <el-table-column label="提交的公司" prop="DistributionFactory"></el-table-column>
        <el-table-column label="年" prop="RegYear"></el-table-column>
        <el-table-column label="月" prop="RegMonth"></el-table-column>
        <el-table-column prop="PersonsCount" label="人员数"></el-table-column>
        <el-table-column prop="SalaryCount" label="金额"></el-table-column>
       
       <el-table-column label="状态" prop="State">
          <template slot-scope="scope">
              <span v-if="scope.row.State ==1">未提交</span>
              <span v-else-if="scope.row.State ==2"> 待审核</span>
              <span v-else-if="scope.row.State ==3"> 已审核</span>
                 <span v-else-if="scope.row.State ==4"> 已发放</span>
                   <span v-else-if="scope.row.State ==0"> 已退回</span>
           
          
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>

    <el-dialog
      title="绩效审核"
      :visible.sync="InsuranceVisible"
      v-model="InsuranceVisible"
      :close-on-click-modal="false"
      width="80%"
    >
      <el-row>
        <el-col
          v-if="buttonList.length > 0"
          :span="24"
          class="organizetoolbar"
          style="padding-bottom: 0px"
        >
          <el-form :inline="true" ref="selectForm" :model="selectForm">
            <el-form-item label="名称:">
              <el-input
                v-model="selectForm.userName"
                placeholder="名称"
              ></el-input>
            </el-form-item>

            <el-form-item label="审核状态:">
              <el-select
                style="width: 100%"
                clearable
                v-model="State"
                placeholder="审核状态"
              >
                <!-- <el-option label="已提交" value="已提交"></el-option> -->
                <el-option label="审核" value="审核"></el-option>
                <el-option label="退回" value="退回"></el-option>
              </el-select>
            </el-form-item>
            <!-- 这个就是当前页面内，所有的btn列表 -->
            <el-form-item>
              <!-- 这里触发点击事件 -->

              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                @click="getInsuranceData()"
                >查询</el-button
              >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                :disabled="isDisabled"
                @click="handleCommany()"
                >提交审核</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table :data="InsuranceData"
                highlight-current-row
                border
                height="500"
                @current-change="selectCurrentRow1"
                @selection-change="handleSelectionChange"
                class="new_table">
          <el-table-column fixed prop="UserName" label="名称"> </el-table-column>
          <el-table-column prop="RegYear" label="年"> </el-table-column>
          <el-table-column prop="RegMonth" label="月"> </el-table-column>
          <el-table-column label="发放公司" show-overflow-tooltip width="150" prop="DistributionFactory"></el-table-column>
          <el-table-column label="公司" show-overflow-tooltip width="150" prop="Factory"></el-table-column>
          <el-table-column label="部门"
                           show-overflow-tooltip
                           width="150"
                           prop="UserDpt"></el-table-column>
          <el-table-column label="岗位"
                           show-overflow-tooltip
                           width="150"
                           prop="UserPost"></el-table-column>
          <el-table-column label="金额"
                           show-overflow-tooltip
                           width="150"
                           prop="Salary"></el-table-column>
          <el-table-column label="类型" prop="Type">
              <template slot-scope="scope">
                  <span v-if="scope.row.Type ==1">绩效季度</span>
                  <span v-else-if="scope.row.Type ==2"> 绩效半年</span>
                  <span v-else-if="scope.row.Type ==3"> 绩效年终</span>

                  <span v-else-if="scope.row.Type ==0"> 绩效月度</span>


              </template>
          </el-table-column>
          <el-table-column label="状态" prop="State">
              <template slot-scope="scope">
                  <span v-if="scope.row.State ==1">未提交</span>
                  <span v-else-if="scope.row.State ==2"> 待审核</span>
                  <span v-else-if="scope.row.State ==3"> 已审核</span>
                  <span v-else-if="scope.row.State ==4"> 已发放</span>
                  <span v-else-if="scope.row.State ==0"> 已退回</span>


              </template>
          </el-table-column>
          <el-table-column label="备注"
                           show-overflow-tooltip
                           width="150"
                           prop="Remark"></el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        align="center"
        @current-change="handleCurrentChange1"
        :current-page="currentPage1"
        :page-size="pageSize1"
        layout="total, prev, pager, next, jumper,total"
        :page-count="total1"
        :total="totaldata1"
      >
      </el-pagination>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
//  import moment from 'moment/moment.js'
import * as validate from "../../../util/validate";
import {
  SalaryReportGetGroupInfoPageList,
  GetSalaryReportList,
  SalaryReportSubmitInfo,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar },
  data() {
    return {
      PositionLists: [],
      fileListUpload: [],
      searchVal: "",
      disbtn: false,
      loading: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      c_row: null,
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      currentPage1: 1, // 当前页码
      total1: null, // 总条数
      totaldata1: null,
      pageSize1: 20, // 每页的数据条数
      page1: 1,
      isDisabled: false,
      SakaryVisible: false,
      InsuranceVisible: false,
      SakaryAllFormVisible: false,
      buttonListmsg: "",
      users: [],
      InsuranceData: [],
      operation: false, // true:新增, false:编辑
      buttonList: [],
      multipleSelection: [],
      PId: "",
        State: null,
        distributionFactory:"",
      Factory: "",
      selectForm: {
        userName: "",
      },
      selectForm1: {
        Year: "",
        Month: "",
      },
      SakaryForm: [],
      filters: {
        name: "",
      },
    };
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (row.State == "已提交") {
        return "warning-row";
      }
      return "";
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectCurrentRow1(val) {
      this.currentRow1 = val;
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.page1 = val;
      this.getInsuranceData(false);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        Factory: this.selectForm1.Month,
      };

      this.$api.MeritSalaryListGetGroupInfoPageList(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getInsuranceData(isCurrent = true) {
     if (isCurrent) {
        this.page1 = 1;
      }
      let para = {
        page: this.page1,
        size: this.pageSize1,
        PId: this.PId,
        userName: this.selectForm.userName,
          distributionFactory: this.distributionFactory,
        //month: this.c_row.Month,
        year: this.c_row.Year,
        };
      this.$api.MeritSalaryListGetInfoPageList(para).then((res) => {
        console.log(res.data);
        this.total1 = res.data.response.pageCount;
        this.totaldata1 = res.data.response.dataCount;
        this.InsuranceData = res.data.response.data;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      this.PId = row.Id;
        this.distributionFactory = row.DistributionFactory;
        this.c_row = row;
        this.getInsuranceData();

      this.InsuranceVisible = true;
    },
    handleCommany() {
      if (!this.State) {
        this.$message({
          message: "请选择审核状态！",
          type: "error",
        });
        return;
      }
      let para = {
        PId: this.PId,
          distributionFactory: this.distributionFactory,
        State: this.State,
      };
       this.$api.MeritSalaryListSubmitInfo(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "设置成功",
            type: "success",
            duration: 5000,
          });
          this.getInsuranceData();
          this.getData();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>

